import React, { useEffect, useRef, useState } from "react";
import "./style.scss";
import ThreeEntryPhone from "./ThreeEntryPhone";

const AlogoPhone = ({ colour }) => {
  const ref = useRef();
  const threeJSInstanceRef = useRef(null);
  const [isModelPreloaded, setIsModelPreloaded] = useState(false);
  let loaded = false;
  const colourPicker = (colour) => {
    switch (colour) {
      case 0:
        return "LinearDefault";
      case 1:
        return "linearLightBlue";
      case 2:
        return "linearMidBlue";
      case 3:
        return "linearDarkBlue";
    }
  };

  useEffect(() => {
    threeJSInstanceRef.current = ThreeEntryPhone(ref.current, colourPicker(colour));

    // Asynchronously preload the GLTF model
    async function init() {
      if (threeJSInstanceRef.current) {
        await threeJSInstanceRef.current.preloadGLTFModel();
        setIsModelPreloaded(true); // Update state to indicate model is preloaded
      }
    }
    init();
  }, [colour]);

  useEffect(() => {
    if (!isModelPreloaded) {
      return; // Skip if the model isn't preloaded yet
    }

    const handleScroll = () => {
      const selfCenter = ref.current.getBoundingClientRect().y + ref.current.getBoundingClientRect().height / 2;
      const self = ref.current.getBoundingClientRect();

      if (threeJSInstanceRef.current != null && !loaded) {
        if (selfCenter <= window.innerHeight * 0.95 && selfCenter >= window.innerHeight * 0.05) {
          loaded = true;
          threeJSInstanceRef.current.loadModel(); // Initialize ThreeEntryPhone
        }
      } else if (threeJSInstanceRef.current != null && loaded) {
        if (self.bottom <= 0 || self.top >= window.innerHeight) {
          loaded = false;
          threeJSInstanceRef.current.resetModel();
        }
      }
    };

    handleScroll();
    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isModelPreloaded]);

  return <div ref={ref} className="canvas-phone"></div>;
};

export default AlogoPhone;
